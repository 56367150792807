.downloads-panel {
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);

	.entry_mcq label{
		margin: 0 0 10px;
	}

	h3{
		text-align: center;
	}

	.ebook-info{
		min-height: 180px;
	}

}
.downloads-panel__group.grid--two-col .downloads-panel__col:first-child {
	@include mq(large) {
    	border-right: 1px solid var(--color-lightest-grey);
	}
}

.downloads-panel__inner {
	@extend .container;
}

.downloads-panel__section-title {
	max-width: var(--content-width);
	margin: 0 auto;
	text-align: center;
}

.downloads-panel__group {
	margin-top: var(--spacing-xxl);
}

.downloads-panel__group.grid--two-col .downloads-panel__col {
	//h3, p { text-align: center; }

	button {
		margin-bottom: 0;
	}

	label {
		font-size: var(--font-size-small);
		margin: 0;
	}

	.downloads-panel__image{
		max-height: 450px;
	}

}

.forms-v1 .downloads-panel__group.grid--two-col{
	.downloads-panel__col:first-child {
	   @include mq(large) {
		   border-right: none;
	   }
   }

   .downloads-panel__col .downloads-panel__image {
		max-height: none; /* or set to the initial value you want */
	}

	.heading-2{
		text-align: left;
	}
}

.downloads-panel__col-inner {
	margin: 0 auto;

	@include mq(large) {
		max-width: 550px;
	}
	@include mq(small,large){
		padding: 0 20px 0;
	}
	
}

.downloads-panel__image {
	margin: 0 auto var(--spacing-xl);
}

.ebook-form{

 	form input[type=text],
	form input[type=email]{
		margin: var(--spacing-s) 0;
		font-family: var(--body-family);
    	font-size: var(--font-size-base);
	}
	
	svg{
		max-width: 30px;
		height: 40px;
		display: inline-block;
		vertical-align: middle;
	}

	#error-message{
		color: var(--color-red);
		text-align: center;
	}

	.sib-hide-loader-icon{
		display: none;
	}
	
}
