
.grid-template{

	padding-top: 0;
	
	.container{
		padding-top: 0;			
	}	


	.heading{

		display: flex;
    	flex-direction: row;
    	padding: var(--section);
    	align-items: center;

		h2{
			padding: 0 var(--spacing);
			width: 33%;
			font-size: var(--font-size-h1);
			font-weight: 700;
			margin: 0;
		}

		.section-description{
			width: 66%;
		}
	}	

	h3.grid-subcategory{
		font-size: var(--font-size-h3);
    	padding: 0 var(--spacing);
    	margin-top: 0;
		padding: var(--spacing-xl) 0;
	}


	
	.grid-items{
		padding-bottom: var(--spacing-xl);

		.grid-item{
			
			.grid-item-title{
				vertical-align: top;
				width: 100%;

				a h4, h4{
					font-size: var(--font-size-h5);
    				color: var(--color-text-base);
				}

			}

			.grid-item-image{

				width: 100%;
				
				img{
					height: auto;
				}	

			}


		}
	}

}

.frontpage-guide{

	padding: 0 var(--section-spacing-l) var(--section-spacing-l);

	h2,h3{
		margin: 0 auto;
		text-align: center;
		padding-bottom: var(--spacing-xxl);
		max-width: var(--container-width);
	}

	.card--article{
		background-color: var(--color-cream);

		img{
			height: auto;
			background-color: #fff;
		}
	}

	.cta-button-section{
		margin: 0 auto;
		width: 100%;
		text-align: center;
		padding: var(--spacing-l) 0 0;
	}

}