.tabs {
	margin-top: var(--spacing);
}

.tabs__nav {
	display: flex;
	list-style: none;
	border-bottom: 1px solid var(--color-nav-grey);
	white-space: nowrap;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.tabs__nav::-webkit-scrollbar {
	display: none;
}

.tabs__switcher {
	border-bottom: 2px solid transparent;
	font-size: var(--font-size-xsmall);
	padding: var(--spacing-xs) var(--spacing-s);
	margin-right: var(--spacing-s);
	cursor: pointer;

	@include mq(medium) {
		padding: var(--spacing-s) var(--spacing);
		font-size: var(--font-size);
	}
}

.tabs__switcher.is-active {
	border-bottom: 2px solid var(--color-primary);
	font-weight: var(--font-weight-bold);
}

.tabs__switcher svg {
	height: 18px;
	width: 18px;
	margin-right: var(--spacing-s);
}

.tabs__switcher .icon-tick {
	color: var(--color-green);
}

.tabs__switcher .icon-cross {
	color: var(--color-red);
	height: 14px;
	width: 14px;
}

.tabs__container {
	margin-top: var(--spacing);
}

.tabs__content h3:first-child {
	margin: var(--spacing-l) 0 var(--spacing-s);
}

.tabs__content .checklist {
	font-size: var(--font-size-small);
}

// Pros/cons
.checklist .has-video {
	@include mq(large) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.checklist__video {
			flex: 0 0 200px;
			margin-left: var(--spacing-l);
		}

		.youtube-popup {
			margin: 0;
		}
	}

	@include mq(xxlarge) {
		.checklist__video {
			flex: 0 0 300px;
		}
	}
}
