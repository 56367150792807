.block-contact-form {
	.subheading {
		font-size: var(--font-size-small);
		margin-bottom: var(--spacing-s);
		@include mq($until:medium) {
			margin-top: var(--spacing-xl);
		}	
	}

	.contact-form__image {
		margin-top: var(--spacing-l);
		@include mq(medium) {
			margin-top: var(--spacing-xl);
		}
	}

	form {
		@include mq(medium) {
			margin-top: var(--spacing-xxl);
		}
		.wpcf7-textarea{
			min-height: 200px;
		}
		.wpcf7-not-valid-tip{
			font-style: normal;
			font-weight: var(--font-weight-normal);
			font-size: var(--font-size-small);
			line-height: var(--line-height);
			font-feature-settings: 'pnum' on, 'lnum' on;
			color: var(--color-red);
		}
	}
	.screen-reader-response{
		font-family: DM Sans;
		font-size: 35px;
		font-weight: 400;
		line-height: 46px;
		letter-spacing: 0em;
		text-align: left;
		display:none;
	}
	.wpcf7-response-output{
		font-size: var(--font-size-h3);
		/* margin-top: var(--spacing-xl); 
		padding: 135px var(--spacing-xxl);*/
		text-align: center;
		max-width: 415px;
	}
	[data-status="sent"] #contact-form{
		display: none;
	}
	.invalid .wpcf7-response-output{
		color: var(--color-red);
		padding: 0;
		font-size: var(--font-size-base);
		display:none;
	}	
}

.contact-form__grid {
	display: grid;
	grid-gap: var(--grid-gap-s);
	grid-template-columns: repeat(12, [col-start] 1fr);
	margin-top: var(--spacing-l);

	.form-grid__info {
		grid-column: col-start / span 12;

		p:first-child {
			margin-top: 0;
		}

		@include mq(medium-major) {
			grid-column: col-start / span 6;
		}
	}
	.form-grid__action {
		grid-column: col-start / span 12;
		@include mq(medium-major) { grid-column: col-start 8 / span 5;  }
	}
}

.chatbox {
	overflow: hidden;
	border: 4px solid #fff;
	border-radius: var(--border-radius);
	padding-top: 100%;
	position: relative;
	@include mq(medium-major) {
		max-width: 450px;
		min-height: 500px;
	}
	@include mq(large) {
		min-height: 680px;
	}
	@include mq(xxlarge) {
		min-height: 740px;
	}

	iframe {
	  position: absolute;
	  top: 0;
	  left: 0;
	  border: 0;
	  width: 100%;
	  height: 100%;
	}
}
