.content {
	p:empty,
	h2:empty {
		display: none;
	}

	p {
		max-width: var(--content-width-s);
	}

	p > img, p > a >img {
		margin-top: var(--spacing-xl);

		@include mq(xlarge) {
			max-width: var(--content-width);
		}
	}

	p img{

		display:inline;

		&.aligncenter{
			display: block;
		}
		
	}

	hr {
		border: 0;
		height: 1px;
		background: var(--color-table-border);
	}

	p.caption {
		margin-top: var(--spacing-s);
		font-size: var(--font-size-small);
		opacity: 0.6;
		max-width: var(--content-width);
	}

	p.disclaimer {
		font-size: var(--font-size-xsmall);
		font-style: italic;
	}

	h2,
	h3,
	h4,
	h5 {
		margin-top: var(--heading-margin);
		margin-bottom: 0;
		max-width: var(--content-width-s);
	}

	a:not(.btn) {
		color: var(--color-text-link);
		text-decoration: none;
	}

	a:not(.btn):hover {
		color: var(--color-text-hover-hover);
		text-decoration: underline;
	}

	blockquote {
		max-width: none;
		font-size: var(--font-size);
		line-height: 1.4;
		margin: var(--spacing-l) 0;
		border-left: 4px solid var(--color-primary);
		padding-left: var(--spacing);
	}

	figure {
		max-width: none;
		margin: var(--spacing-xl) 0;
	}

	figcaption {
		padding: 1em 0;
		font-size: var(--font-size-xs);
		color: var(--color-text-dark);
		max-width: var(--content-width);
		margin-right: auto;
		margin-left: auto;
		border-bottom: 1px solid var(--color-grey-light);
	}

	ul:not(.checklist, .tabs__nav, .checklist-pros ul, .checklist-cons ul) {
		margin-top: 1.5em;
		padding: 0 0 0 1rem;
		max-width: var(--content-width-s);
		list-style: disc;

		li {
			position: relative;
			padding-left: 0.2em;
			margin-bottom: 0.3em;
		}

		li p {
			display: inline;
		}

		// li:before {
		// 	content: "•";
		// 	position: absolute;
		// 	left: 0;
		// 	top: 0;
		// 	font-size: 1.7rem;
		// 	line-height: 1;
		// 	font-weight: bold;
		// 	margin-right: 0.6em;
		// 	color: var(--color-text-dark);
		// }
	}

	ul.primary-dot {
		li:before {
			color: var(--color-primary);
		}
	}

	ol {
		list-style: decimal;
		padding-left: 1.5rem;
		max-width: var(--content-width-s);

		li {
			margin-bottom: 0.25rem;
		}
	}

	.unformatted-list ul {
		margin-top: 0;
		margin-bottom: 0;
		list-style: none;

		li:before {
			display: none;
		}
	}

	section > .embed-responsive {
		margin-top: var(--spacing);
	}

	.embed-responsive + p {
		margin-top: var(--spacing-s);
	}

	&.full-width-text {
		h2,
		h3,
		h4,
		h5,
		p,
		ul:not(.checklist, .tabs__nav, .checklist-pros ul, .checklist-cons ul) {
			max-width: 100%;
		}
	}

	.checklist-pros,
	.checklist-cons {
		ul {
			margin-top: 1.5em;
			padding: 0;
			max-width: var(--content-width-s);
		}

		ul li:before {
			top: 3px!important;

			@include mq(medium) {
				top: 5px!important;
			}
		}
	}

	td ol,td ul{
		margin-top: 0 !important;
	}
}

.well {
	background: var(--color-cream);
	padding: var(--spacing-l);
	margin: var(--spacing) 0;

	h3, h4, h5 {
		margin: 0;
		text-transform: uppercase;
		color: var(--color-text-alt);
		font-size: var(--font-size);
	}

	p {
		font-size: var(--font-size-small);
	}

	p:first-child {
		margin-top: 0;
	}

	.btn {
		margin-bottom: 0;
	}

	@include mq(large) {
		padding: var(--spacing-xl);
	}
}

.well--border {
	border-top: 1px solid var(--color-primary);
}

.well-1,
.info-box-text {
	font-size: var(--font-size-lead);
	border-left: 4px solid var(--color-primary);
	/*font-weight: var(--font-weight-bold);*/
	line-height: var(--line-height-small);
	padding-left: var(--spacing);
	margin-top: var(--spacing-l);
	margin-bottom: var(--spacing-xl);
}

.info-box-text {
	max-width: var(--content-width-s);
}

.info-tooltip {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: var(--color-lightest-grey);
	height: 16px;
	width: 16px;
	font-size: 13px;
	line-height: 1;
	border-radius: 20px;
	text-transform: lowercase;
	margin-left: 4px;
	position: relative;
	top: -1px;

	span {
		display: none;
	}
}

.content--wide {
	p {
		max-width: none;
	}
}

.show-more:not(.is-active){
	height: 0;
    opacity: 0;
    transition: visibility .35s ease-in-out,opacity .35s ease-in-out,height .35s ease-in-out;
    visibility: hidden;
	display: none;
}

.rounded{
	border-radius: var(--border-radius);
}

.fancybox__content .content p>a>img, 
.fancybox__content .content p>img{
	max-width: 100%;
}