.form {

	input[type="email"],
	input[type="number"],
	input[type="search"],
	input[type="text"],
	input[type="tel"],
	input[type="url"],
	input[type="password"],
	textarea,
	select {
		width: 100%;
		height: 48px;
		padding: 6px 10px;
		background-color: #fff;
		border: 1px solid #8c8d90;
		border-radius: 10px;
		box-shadow: none;
		box-sizing: border-box;
	}

	input[type="email"],
	input[type="number"],
	input[type="search"],
	input[type="text"],
	input[type="tel"],
	input[type="url"],
	input[type="password"],
	textarea {
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	textarea {
		min-height: 65px;
		padding-top: 6px;
		padding-bottom: 6px;
	}

	input[type="email"]:focus,
	input[type="number"]:focus,
	input[type="search"]:focus,
	input[type="text"]:focus,
	input[type="tel"]:focus,
	input[type="url"]:focus,
	input[type="password"]:focus,
	textarea:focus,
	select:focus {
		border: 1px solid var(--color-grey);
		outline: 0;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M1 1L6.5 7L12 1' stroke='%231A1C22'/%3E%3C/svg%3E%0A") no-repeat 95% center;
		background-size: 13px;
		color: var(--color-text-base);
	}

	label {
		display: block;
		margin: var(--spacing) 0;
	}

	input[type="checkbox"],
	input[type="radio"] {
		display: inline;
	}

	button[type="submit"],
	input[type="submit"],
	input[type="reset"],
	input[type="button"] {
		@extend .btn;
	}

	.required {
		color: #BF5D58;
	}

	.form__checkbox {
		display: flex;
		align-items: flex-start;

		label {
			margin: 0;
		}

		input[type="checkbox"] {
			margin-right: var(--spacing-s);
			&.mce_inline_error {
				box-shadow: inset 0 0 1px 1px #6B0505;
			}
		}

		a {
			color: var(--color-primary);
		}
	}

	#mce-error-response   { color: #6B0505; }
	#mce-success-response { color: var(--color-primary); }

	ul {
		margin: 0;
		padding: 0;
	}
}

.cp-load-after-post{
	display: block;
    margin-top: var(--spacing-xxl);
}

.ebook-form {

		.entry_mcq label, .listsize, .entry__error{
			margin: 0 0 10px !important;
		    font-size: var(--font-size-small);
    		font-weight: 400!important;
		}

		#LISTSIZE{
			height: 32px;
			font-family: var(--body-family);
    		font-size: var(--font-size-base);
		}

}
