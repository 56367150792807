.block-guide-download {

    border-top: 1px solid var(--color-text-base);
    padding-top: var(--section-spacing);

	h2 {
		margin: 0 0 var(--spacing);
		font-size: var(--font-size-h4);
	}

	@include mq(large) {
		.grid{
			grid-gap: var(--grid-gap-s);
			grid-template-columns: 1fr 1fr;
		}
	}
	
}
