.page-header--article {
	.page-header__inner {
		padding-bottom: var(--spacing-l);
		padding-top: var(--spacing-l);
		@include mq(medium-major) {
			padding-bottom: var(--spacing-xxl);
			padding-top: 0.725rem;
			display: grid;
	    	grid-gap: var(--grid-gap);
	    	grid-template-columns: 3fr 1fr;
			align-items: center;
	    }

	    &.no-image {
	    	display: block;
	    	text-align: center;
	    	.page-header__meta { justify-content: center; }
	    	.breadcrumbs { text-align: left; }
	    	.page-header__content { text-align: center; }

	    	h1 {
	    		text-align: center;
				margin: var(--spacing) auto 0;
	    		@include mq(large) {
	    			max-width: 75%;
	    			margin: var(--spacing-l) auto 0;
	    		}
	    	}
	    }
	}

	.page-header__breadcrumbs-wrap {
	    margin: 0 auto;
	    padding: 0 1em;
	    width: 100%;
	    max-width: var(--container-width);
		padding-top: var(--spacing-xl);
		@include mq(medium-major) {
			padding-top: 1.725rem;
	    }

	    p { margin: 0; }
	}

	.page-header__image {
		margin-top: var(--spacing-xl);
		width: 100%;
		height: auto;
		max-width: 323px;
		margin: 30px auto 0;
		@include mq(medium-major) {
			margin-top: 0;
		}
	}

	.page-header__content {
		display: flex;
		flex-direction: column;
		text-align: left;

		.page-header__category {
			margin: 0;
			font-size: var(--font-size-xsmall);
			font-weight: var(--font-weight-bold);
			text-transform: uppercase;
			a {
				color: var(--color-text-base);

				&:hover {
					color: var(--color-primary);
				}
			}
		}

		h1 {
			font-weight: var(--font-weight-bold);
			margin-top: var(--spacing-s);

			span {
				display: block;
				font-weight: var(--font-weight-normal);
				font-size: var(--font-size-h2);
			}


			@include mq(medium-major) { text-align: left; }
		}

		h2 {
			font-size: var(--font-size-h4);
			margin-top: var(--spacing);
		}

		.page-header__description {
			max-width: 800px;
			margin: var(--spacing) auto 0;
		}

		.page-header__meta {
			display: flex;
			align-items: center;
			margin-top: var(--spacing-s);
			margin-bottom: auto;
			text-align: left;
			// @include mq(medium-major) { text-align: center; }

			.page-header__author-images {
				display: flex;
				img { margin-right: var(--spacing-s); }
			}
			.page-header__author {
				margin-top: 0;
				font-weight: 500;
				margin: 0 var(--spacing-s);
				font-size: var(--font-size-small);

				a {
					font-weight: var(--font-weight-bold);
				}
			}
			time {
				color: var(--color-text-light);
				font-size: var(--font-size-small);
			}
			.review-section-header__link{
				color: var(--color-text-link);
    			text-decoration: none;
				font-size: var(--font-size-xsmall);
				text-align: left;
			}
		}
	}
}
