:root {
	--rainbow-angle: 45deg;
	--rainbow-opacity: 0.5;
	--border-size: 0.3rem;
}

.lazy-quiz{

	display: block;
    margin: var(--spacing-l) 0;

	.btn{
		min-width: 300px;
    	max-width: 75%;
    	width: auto;
	}	

	img{
		margin: var(--spacing-xs) auto 0 -5px;
		border-radius: 10px;
    	border: var(--border-size) dotted transparent;
		cursor: pointer;
	}
}
  
  *,
  *::before,
  *::after {
	box-sizing: border-box;
}
  
.rainbow {
	border-radius: 10px;
  
	font-size: 1.5em;
  
	border: var(--border-size) dotted transparent;
	background-image: linear-gradient(
		to right,
		rgb(255 255 255 / var(--rainbow-opacity)),
		rgb(255 255 255 / var(--rainbow-opacity))
	  ),
	  conic-gradient(
		from var(--rainbow-angle),
		#57C0F9 0deg 90deg,
		#81E4AE 90deg 180deg,
		#F9D02D 180deg 270deg,
		#EA663B 270deg 360deg
	  );
	background-origin: border-box;
	background-clip: padding-box, border-box;
  }
  
  /* Animate when Houdini is available */
  @supports (background: paint(houdini)) {
	@property --rainbow-opacity {
	  syntax: "<number>";
	  initial-value: 0.5;
	  inherits: false;
	}
  
	@property --rainbow-angle {
	  syntax: "<angle>";
	  initial-value: 0deg;
	  inherits: false;
	}
  
	@keyframes opacityChange {
	  to {
		--rainbow-opacity: 1;
	  }
	}
  
	@keyframes rotate {
	  to {
		--rainbow-angle: 360deg;
	  }
	}
  
	.rainbow {
	  animation: rotate 4s linear infinite, opacityChange 3s infinite alternate !important;
	}
  
	
  }