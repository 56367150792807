.related-posts{

	/*display: grid;
    grid-gap: var(--grid-gap-sm);
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto;
    justify-content: center;*/
	padding: var(--spacing-xl);

	h2{
		grid-column: 2 / 12;
    	grid-row: 1;
    	width: 100%;
    	text-align: center;
		font-size: var(--font-size-h3);
	}

	.review-panel__cards{
		grid-column: 2 / 12;
		grid-row: 2;
	
		.card__header {
			padding: var(--spacing);
		}
	
		h3{
			font-size: var(--font-size-small);
			font-weight: var(--font-weight-bold);
		}

		h3 span {
			display: block;
			font-weight: var(--font-weight-normal);
		}
		.card__footer {
			padding: var(--spacing);	
		}
	}

	
	.card--email:hover .card__header {
		background: none;
	}

	
	@include mq(large) {

		h2{
			grid-column: 3 / 11;
		}

		.review-panel__cards{
			grid-column: 3 / 11;
			grid-row: 2;
		}

		.card__footer {
			padding: 0 var(--spacing-l) var(--spacing-l) var(--spacing-l);
			min-height: 135px;		
		}
	}	
}


