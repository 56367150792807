.team-panel {
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);
	overflow: hidden;
}

.team-panel__member {
	@include mq(medium) {
		display: flex;
	}
}

.team-panel__image {
	margin-bottom: var(--spacing-xl);
	max-width: 300px;
	border-radius: var(--border-radius);
	@include mq(medium) {
		margin: 0 var(--spacing-xl) var(--spacing-xl) 0;
		align-self: flex-start;
	}
	@include mq(large) {
		max-width: 150px;
	}
	@include mq(xlarge) {
		max-width: 250px;
	}
	@include mq(xxlarge) {
		max-width: 300px;
	}
}

.team-panel__bio {
	.team-panel__name a {
		color: var(--color-text-base);

		&:hover {
			color: var(--color-text-base);
		}
	}

	.team-panel__role {
		font-size: var(--font-size-small);
		text-transform: uppercase;
		margin-top: var(--spacing-s);
		color: var(--color-text-light);
	}

	p a{
		color: var(--color-text-link);
	} 

}

.team-swiper .swiper-slide {
	width: 85%;
}

.show-more-btn.is-active {
	display: none;
}
