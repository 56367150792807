.pricing-block {
	margin-top: var(--section-spacing);
	margin-bottom: var(--section-spacing);
}

.pricing-block h2 {
	margin-bottom: var(--section-spacing);
}


.pricing__row {
	display: flex;
	align-items: flex-start;
	margin: var(--spacing-l) 0;
}

.pricing__value {
	flex: 0 0 18%;
	line-height: 1;
	font-size: var(--font-size-h4);
	font-weight: var(--font-weight-bold);
	margin-right: 10px;

	@include mq(medium) {
		font-size: var(--font-size-h3);
		font-weight: var(--font-weight-normal);
	}
}

.pricing__description {
	flex: 1;
}

h3.pricing__name {
	margin: 0;
	font-size: var(--font-size-h4);
	font-weight: var(--font-weight-bold);
}

.pricing__description p {
	margin: 0;
	font-size: var(--font-size-small);
}

#pricing {
	
	.load__more {
    	margin-top: 0;
	}

}
