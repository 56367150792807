.landing {

	.btn, .form button[type=submit]{
		width: 100%;
	}

	.hero-header{

		.hero-header__inner{
			text-align: left;
			display: flex;
    		flex-wrap: wrap;

			.hero-header__content{
				margin: var(--spacing-l) 0;
				order: 2;
			}
			.hero-header__media{
				order: 1;
			}

			@include mq(large) {

				.hero-header__content{
					order: 1;
				}
				
				.hero-header__media{
					order: 2;
				}

			}

		}		
		
		p{
			font-weight: var(--font-weight-medium);
			color: var(--color-text-base);
			line-height: 135.2%;	
			-webkit-font-smoothing: auto;
		}

		li{
			list-style: none;
			color: var(--color-dark);
			font-size: var(--font-size-small);
			font-weight: var(--font-weight-normal);
			line-height: 28px;

		/*   &::before {
				color: #e97979;
				content: "•";
				display: inline-block;
				font-size: 1.4em;
				line-height: 28px;
				margin-left: -1em;
				width: 1em;
			} */

			&::before {
				content: "";
				display: inline-block;
				width: 6px;
				height: 6px;
				margin-right: 10px;
				background-color: #E97979;  // Color of the bullet
				border-radius: 50%;  // To make it round
				vertical-align: middle;  // To align it with the text
			}

			&:not(:first-child){
				margin-top: var(--spacing);
			}

			
		}

		.presentation__people{
			align-items: center;
			margin: 0 auto auto;
			margin-top: var(--spacing-s);
			max-width: var(--container-width);
			padding: 0 var(--spacing-xl) var(--spacing-xl);
			text-align: left;
			width: 100%;
			display: block;

			.presentation__people-images{
				display: flex;

				img{
					margin-right: var(--spacing-s);
				}
			}

			.presentation__people-text{
				font-size: var(--font-size-small);
				font-weight: 500;
				margin: var(--spacing-xl) var(--spacing-s) 0;

			}

			@include mq(large) {
				display: flex;
				padding: 0 var(--spacing-xl) var(--spacing-xl);

				.presentation__people-text{
					@include mq(large) {
						margin: 0 var(--spacing-s);
					}	
				}

			}	
		}

		


	}

	.feature-panel {
		
		.feature-panel__media{

			img{
				border-radius: 10px;
			}

		}


		@include mq(large) {
			.feature-panel__content {
				border-left: 2px solid #F9D02D;
				padding-left: 25px;

				p{
					margin-top: 0;
					padding-bottom: 10px;
				}
			}
		}

		.youtube{
			
			border-radius: 10px;

			.play-button {
				background: transparent;
			}

		}

		
	}



	.author-bio {

		padding: var(--spacing-xxl);
		
		margin-right: 0;
	
		.container{

			padding: 0;
			max-width: 922px;

			.author-bio__image{
				
				flex-basis: 365px;
				margin-right: var(--spacing);

				img{
					border-radius: 10px;
					width: 335px;
				}	

			}

			.author-bio__content{
				
				width: 575px;
				max-width: 100%;

				h2.author-bio__name{
					font-size: var(--font-size-h2-s);
					margin-bottom: var(--spacing);
				}

				h3.author-bio__name{
					font-size: var(--font-size-xsmall);
					margin-bottom: 5px;
				}

				.author-bio__meta{
					font-size: 12px;
					letter-spacing: 0.48px;
					text-transform: uppercase;
				}

				.author-bio__desc,
				.author-bio__desc p{
					color: var(--color-dark);
					line-height: var(--line-height-small);
				}

			}

		}

		@include mq(large) {
			padding-right: var(--spacing-l);
			padding-bottom: var(--section-spacing-l);
			padding-top: var(--section-spacing-l);
		}	
	}	
	
	/* Landing ebook styles */

	.landing-ebook{
		
		.container{
			
			
			h2{
				font-size: var(--font-size-h3);
			}

			.ebook-image{
				display: none;		
			}

			@include mq(large) {

				grid-gap: var(--grid-gap);
				display: grid;
				grid-template-columns: 1fr 1fr;


				.ebook-image{
					display: block;
					max-width: auto;
				}

			}

		}

		.ebook-form-code{
			padding: var(--spacing-l) 0;

			.ett-sib-form{

				#sib-form-container{
					padding: 0; 
					 margin: 0;
				}

				.entry_mcq label{
					display:inline;
				}
				
				.sib-form{
					padding: 1em 0;
					margin: 0;
				}
				
				.sib-form-block {
					padding: 0;
				}
				
				.ett-popup {
					display: none;
					position: absolute;   
					top: 100%; 
					left: 0; 
					background-color: var(--color-white);
					border: 1px solid var(--color-dark-grey);  
					padding: 10px; z-index: 10;
					background-color: var(--color-quinary-light);
				}
				
				input:focus + .ett-popup { 
					display: block;
				}
				
				.sib-radiobutton-group input:focus + .popup {
					display: block;
				}
				
				.sib-radiobutton-group:hover .ett-popup {
					display: block;
				}
				
				.entry__choice{
					width: min-content;
					display: inline;
				}
				
				.sib-form .input:not(textarea), 
				.sib-form .input__button {

					height: calc(2.5rem - 2px);

				}

				.sib-captcha {
					padding: var(--spacing) 0 0;
				
				}


				.sib-form-message-panel, 
				.ett-sib-form .sib-form-message-panel  {
					margin: 0 0 1.25rem;
					width: 100%;
					padding: .4375rem;
					border: 1px solid;
					display: none;
				}

				.form label{
					margin: 0;
					padding: var(--spacing) 0 var(--spacing-xs);
				}

				.sib-radiobutton-group{
					padding: var(--spacing-l) 0 0;
				}

				.entry__error {
					display: none;
				}

				.entry__error,
				.entry_mcq label,
				listsize {
					font-size: var(--font-size-base);
					font-weight: var(--font-weight-normal) !important;
					margin: 0 !important; 
				}

				

			}

			@include mq(large) {
				
			}	

		}

		 
	 
	}

	.landing-four-columns{
		
		/* Container for the carousel */
		.landing-four-columns__carousel {
			overflow-x: auto;
			scroll-snap-type: x mandatory;
			display: flex;
			scroll-behavior: smooth;
			-webkit-overflow-scrolling: touch;
		}

		.container{
			display: grid;
			grid-gap: var(--grid-gap-s);;
			max-width: 1300px;
			margin: 0 auto;

			h2{
				margin: 0 auto var(--section-spacing-l);
				text-align: center;
				color: var(--color-gray);
				font-size: var(--font-size-h2-s);
				line-height: normal;
			}

			.landing-four-columns__column{
				border-radius: 10px;
				padding: 24px;
				min-height: 395px;
				min-width: 300px;
				background-repeat: no-repeat;
				background-size: cover;
				background-color: var(--color-cream);
				background-size: 90%;

				h3{
					font-size: var(--font-size-small);
					font-weight: var(--font-weight-medium);
					-webkit-font-smoothing: auto;
				}

				p{
					color: var(--color-dark);
					font-size: var(--font-size-xsmall);
					font-weight: var(--font-weight-normal);
					line-height: 21px; 
				}
			}	

			

			/* Each column */
			.landing-four-columns__column {
				flex: 0 0 100%;
				scroll-snap-align: start;
				box-sizing: border-box;
			}
		}
	}

	.landing-testimonials{

		padding-bottom: var(--section-spacing-l);
		padding-top: var(--section-spacing);
		position: relative;

		&::before {
			background: url(../images/white-curve.svg) no-repeat;
			content: " ";
			display: block;
			height: 60px;
			left: 50%;
			position: absolute;
			top: -15px;
			transform: translateX(-50%);
			width: 60px;
			z-index: 1;
		}

		.landing-testimonials__inner{
			max-width: 1300px;
			margin: 0 auto;
			overflow: hidden;
		}

		.landing-testimonials__header{

			text-align: center;
			color: var(--color-white);
			margin: 0 auto var(--section-spacing);
			width: 100%;

		 	h2{
				font-size: var(--font-size-h2-s);
			}

		}

		blockquote{
			color: var(--color-white);
			text-align: left;
			border: none;
			overflow: hidden;
			padding: 0 1em;
		}

		.testimonial-carousel__title {
			text-align: center;
		}
		
		.testimonial-slide__inner{
			flex-direction: unset;
			display: grid;
    		grid-template-rows: 3fr 2fr;
		}


		.testimonial-slide__content {
		}

		.testimonial-slide__media {
			grid-template-columns: 1fr 12fr;
			display: grid;
			padding: 1em;

			img{
				max-width: 90px;
			    height: auto;
				border-radius: 10px;
			}

			.landing-media__description{

				padding: 0 0 0 1em;

				cite{
					color: var(--color-white);
					text-align: left;
					margin: 0;
					display: block;
				}

				h4{
					text-transform: uppercase;
					display: block;
					color: #fff;
					opacity: 0.6;
					font-size: var(--font-size-xsmall);
					text-align: left;
					a{
						color: #fff;
						::hover{
							color: #fff;
						}
					}
				}

			}

		}

		.testimonials-swiper{
			padding: 0 30px;
		}

		.testimonial-slide.swiper-slide {
			background-color: var(--color-dark-grey);
			transition: transform 0.3s; // Add transition for smooth scaling
		}
		
		.swiper-slide {
			text-align: center;
			font-size: 18px;
			background-color: var(--color-dark-grey);
			min-height: 370px;
			max-width: 815px;
			width: 100%;
		}


		.swiper-slide:not(.swiper-slide-active) {
			transform: scale(0.8) !important;
		}

		.swiper-container {
			padding: 0 30%; // Adjust this to control how much of the adjacent slides are visible
		}
		
		.swiper-slide {
			width: 60%; // Adjust this to control the width of the slides
		}

		.swiper-one-slide{
			margin: auto;
			min-height: 340px;
			max-width: 815px;
			width: 100%;
		}

		.landing-navigation
		{	
			display:none;
			
			@include mq(large) {
				display: block;
				float: right;
				position: relative;

				.swiper-button-next, .swiper-button-prev{

					color: var(--color-white) !important;
					cursor: pointer  !important;
					display: inline-block  !important; 
					position: relative !important;
					margin-right: 30px;

				}
			}
		}
	}

	@include mq(large) {
		.btn, .form button[type=submit]{
				width: auto;
		}
	}	

}
