.social-links {
	display: flex;
	margin: 0;
    justify-content: center;
	
	@include mq(large) {
    	justify-content: right;
		margin-top: var(--spacing-s) !important;
		margin-right: var(--spacing) !important;
	}
}

.social-links__item {
	margin: 0;
}

.social-links__link {
	color: white;

	&:hover {
		color: white;
	}
}
.social-links__link .icon {
	height: 48px;
    width: 48px;
}
#social-links__rss svg {
	width: 35px;
    height: 25px;
    margin-top: 13px;
}