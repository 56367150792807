.who-wrote-this-panel {
	padding-top: var(--section-spacing);
}

.who-wrote-this__card {
	border-top: 2px solid var(--color-primary);
	padding: var(--spacing-l);
	background-color: var(--color-cream);
	text-align: center;

	ul{
		list-style: disc;
    	margin-top: 1.5em;
    	max-width: var(--content-width-s);
    	padding: 0 0 0 1rem;
	}
	
	ul li{
		display: list-item;
		padding-left: 0.2em;
		position: relative;
	}

	@include mq(medium) {
		padding: var(--spacing-xl);
		display: flex;
		text-align: left;
	}
}

.who-wrote-this__image {
	margin: 0 auto;
	align-self: flex-start;
}

.who-wrote-this__content {
	margin-top: var(--spacing-xl);
	@include mq(medium) {
		margin-top: 0;
		margin-left: var(--spacing-xl);
	}

	.who-wrote-this__header {
		text-transform: uppercase;
		opacity: 0.6;
		font-size: var(--font-size-small);
	}
}