.card {
	display: flex;
	flex-direction: column;
	background: var(--color-white);
	border-radius: var(--border-radius);

	&:hover {
		text-decoration: none;
	}

	&:hover .card__header {
		color: var(--color-white);
	}

	@include mq(medium) {
		margin: 0;
	}
}

.card__header {
	padding: var(--spacing);
	border-radius: var(--border-radius) var(--border-radius) 0 0;

	h3{
		
		font-weight: var(--font-weight-bold);
		span{
			font-weight: var(--font-weight-normal);
    		display: block;
		}

	}
	@include mq(medium) {
		padding: var(--spacing-l);
	}
}

.card__prefix {
	margin: 0;
	font-size: var(--font-size-small);
	font-weight: var(--font-weight-bold);
	text-transform: uppercase;

	.card:hover & {
		color: var(--color-white);
	}
}

.card__footer {
	margin-top: auto;
	padding: var(--spacing);

	@include mq(medium) {
		padding: var(--spacing-l);
	}
}

.card__author {
	color: var(--color-text-light);
	font-size: var(--font-size-small);
	line-height: 1.3;

	span {
		display: block;
		font-size: var(--font-size-small);
		font-weight: var(--font-weight-bold);
		color: var(--color-text-base);
	}

	a {
		font-weight: var(--font-weight-bold);
	}
}

.card__category {
	margin: 0;
	font-size: var(--font-size-xsmall);
	font-weight: var(--font-weight-bold);
	text-transform: uppercase;
}

.card__author-images {
	display: none;
	margin-top: var(--spacing-s);

	.user-user-profile-small,
	.user-profile {
		display: block;
		height: 75px;
		margin-right: 5px;
	}

	@include mq(medium) {
		display: flex;
	}
}

.card__excerpt {
	color: var(--color-text-light);
	margin: var(--spacing-s) 0;
	line-height: var(--line-height-small);
}

//May need to review this – should some of this be more global? It's a fairly common pattern
.card__meta {
	display: flex;
	align-items: center;
	margin-top: var(--spacing-s);

	.card__author-images {
		display: flex;
		img { margin-right: var(--spacing-xs); }
	}
	.card__author {
		margin-top: 0;
		font-weight: 500;
		color: var(--color-text-base);
		margin: 0 var(--spacing-s);
		font-size: var(--font-size-small);
	}
	time {
		color: var(--color-text-light);
		font-size: var(--font-size-small);
	}
}

// Category colours
.card--website {
	&:hover .card__header {
		background: var(--color-primary);
	}

	.card__prefix {
		color: var(--color-primary);
	}
}

.card--ecommerce {
	&:hover .card__header {
		background: var(--color-secondary);
	}

	.card__prefix {
		color: var(--color-secondary);
	}
}

.card--email {
	&:hover .card__header {
		background: var(--color-quaternary);
	}

	.card__prefix {
		color: var(--color-quaternary);
	}
}

.card--crm {
	&:hover .card__header {
		background: var(--color-quinary);
	}

	.card__prefix {
		color: var(--color-quinary);
	}
}

.card--hosting {
	&:hover .card__header {
		background: var(--color-tertiary);
	}

	.card__prefix {
		color: var(--color-tertiary);
	}
}

.card--featured {
	@include mq(medium) {
		display: grid;
		grid-gap: var(--grid-gap-s);
		grid-template-columns: 1fr 1fr;
	}

	.card__footer {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		grid-gap: 0;
	}

	.card__categories li {
		color: var(--color-primary);
	}

	.card__title {
		font-weight: var(--font-weight-bold);
		margin: var(--spacing) 0;
		span{
			font-weight: var(--font-weight-normal);
			display: block;
		}
		@include mq(large) { font-size: var(--font-size-h1); }
	}

	.card__excerpt {
		line-height: var(--line-height);
	}

	.card__meta {
		margin-top: auto;
		align-items: flex-start;
	}

	.card__author {
		color: var(--color-text-light);

		span {
			color: var(--color-text-base);
		}
	}
}

.card--article {
	height: 100%;

	.card__title {
		font-size: var(--font-size-h4);
		font-weight: var(--font-weight-bold);
		margin: var(--spacing-s) 0;

			span{
				font-weight: var(--font-weight-normal);
				display: block;
			}
			
	}

	.card__footer {
		padding-top: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.card__categories {
		display: flex;
		width: 100%;

		li {
			margin-right: 10px;
		}
	}

	.card__meta {
		margin-top: auto;
	}
}

// Card alternative
.card.card--alternative {
	box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.04);
	align-items: center;
	padding: var(--spacing-l);

	img {
		max-height: 70px;
		max-width: 140px;
	}

	.btn {
		margin-bottom: 0;
	}

	.card__logo-link {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 70px;
		margin-bottom: var(--spacing-l);
	}

	.stars {
		margin: auto auto 0;
	}
}

.block-blog-articles .card__meta {

	display: block;
    float: left;
	width: 100%;

	img{
		margin-right: var(--spacing-xs);
		float: left;
	}

	.card__author {
		color: var(--color-text-base);
		font-size: var(--font-size-small);
		font-weight: 500;
		margin: 0 var(--spacing-s);
		display: block;
		width: auto;
		max-width: 115px;
		float: left;
		margin-top: var(--spacing-xs);
	}

	.card__author-images{
		display: block;
    	float: left;
	}

	time {
		color: var(--color-text-light);
		font-size: var(--font-size-small);
		display: block;
		min-width: 50px;
		float: right;
		max-width: 65px;
	}
}