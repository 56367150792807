:root {
	--body-family: 'DM Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	--display-family: 'DM Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

	--font-size-base: 16px;
	--font-size: 1rem;
	--font-size-lead: 1.1rem;
	--font-size-small: 0.95rem;
	--font-size-xsmall: 0.8rem;
	--font-size-h1: 1.8rem;
	--font-size-h2: 1.6rem;
	--font-size-h2-s: 1.5rem;
	--font-size-h3: 1.4rem;
	--font-size-h4: 1.1rem;
	--font-size-h5: 1rem;
	--font-size-h6: 1rem;

	--font-weight-light: 100;
	--font-weight-normal: 400;
	--font-weight-medium: 500;
	--font-weight-bold: 700;

	--line-height: 1.6;
	--line-height-headings: 1.1;
	--line-height-small: 1.5;

	--heading-margin: 1.6em;

	--color-white: #ffffff;
	--color-grey: #1A1C22;
	--color-dark :#222;
	--color-dark-grey: #282B33;
	--color-light-grey: #BABBBD;
	--color-lighter-grey: #ccc;
	--color-lightest-grey: #D1D2D3;
	--color-blue: #0075FF;
	--color-cream: #FAF7F1;
	--color-cream-dark: #efe8db;
	--color-nav-grey: #E8E8E9;
	--color-green: #8FE0B4;
	--color-red: #F62F5F;

	 // blue – website builders
	--color-primary: #57C0F9;
	--color-primary-light: #DAEEFB;

	// green – ecommerce
	--color-secondary: #81E4AE;
	--color-secondary-light: #D0FCE0;

	// purple – hosting
	--color-tertiary: #8131BD;
	--color-tertiary-light: #E7D4FC;

	// orange – email marketing
	--color-quaternary: #EA663B;
	--color-quaternary-light: #F1C6B7;

	// yellow – crm
	--color-quinary: #F9D02D;
	--color-quinary-light: #FFFDF3;

	// lilac
	--color-senary: #E7A5FF;

	// pink
	--color-septenary: #FFB6CD;

	// turquoise
	--color-octonary: #24EECA;

	// red
	--color-nonary: #F62F5F;

	// lime
	--color-denary: #C4FC7D;

	--color-text-base: #1A1C22;
	--color-text-alt: #333744;
	--color-text-light: #808080;
	--color-text-reversed: #fff;
	--color-text-link: #57C0F9;
	--color-text-hover: var(--color-primary);
	--color-table-border: #EAEAEB;

	--color-facebook: #3B5998;
	--color-twitter: #1DA1F2;
	--color-instagram: #262626;
	--color-youtube: #cc181e;
	--color-linkedin: #0077B5;

	--body-background-color: #fff;

	--border-radius-sm: 5px;
	--border-radius: 10px;

	--spacing-jumbo: 8rem;
	--spacing-xxxl: 5rem;
	--spacing-xxl: 3rem;
	--spacing-xl: 2rem;
	--spacing-l: 1.5rem;
	--spacing: 1rem;
	--spacing-s: 0.5rem;
	--spacing-xs: 0.25rem;

	--section-spacing: 2rem;
	--section-spacing-l: 4rem;

	--grid-gap-s: 1rem;
	--grid-gap-sm: 1.5rem;
	--grid-gap: 2rem;
	--grid-gap-l: 4rem;

	--site-width: 1600px;
	--container-width: 100%;
	--container-width-xs: 900px;
	--container-width-s: 1200px;
	--container-width-m: 1300px;
	--container-width-l: 1600px;
	--content-width: 780px;
	--content-width-s: 660px;

	@include mq(medium) {
		--container-width: 640px;
		--font-size-h1: 2rem;
		--font-size-h2: 1.8rem;
		--font-size-h2-s: 1.7rem;
		--font-size-h3: 1.6rem;
		--font-size-h4: 1.12rem;
		--font-size-h5: 1rem;
		--font-size-h6: 1rem;
		--section-spacing: 3rem;
		--section-spacing-l: 6rem;
		--grid-gap-s: 1rem;
		--grid-gap-sm: 2rem;
		--grid-gap: 4rem;
		--grid-gap-l: 8rem;
	}

	@include mq(medium-major) {
		--container-width: 740px;
	}

	@include mq(large) {
		--container-width: 1024px;
		--font-size-base: 19.8px;
		--font-size-small: 0.9rem;
		--font-size-xsmall: 0.8rem;
		--font-size-lead: 1.1rem;
	}

	@include mq(xlarge) {
		--container-width: 1200px;
		--font-size-h1-home: 3rem;
		--font-size-h1: 2.65rem;
		--font-size-h2: 2.1rem;
		--font-size-h2-s: 1.8rem;
		--font-size-h3: 1.5rem;
		--font-size-h4: 1.12rem;
		--font-size-h5: 1.1rem;
		--font-size-h6: 1rem;
	}

	@include mq(xxlarge) {
		--container-width: 1400px;
	}

	@include mq(xxxlarge) {
		// --container-width: 1600px;
	}
}
