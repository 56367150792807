.review-header-wrap {
	margin-top: 52px;
	margin-bottom: var(--section-spacing);

	@include mq(large) {
		margin-top: 67px;
	}
}

.review-header {
	margin-top: 52px;
	margin-bottom: var(--section-spacing);
	background: white;
	// transition: all 200ms linear;

	@include mq(large) {
		margin-top: 90px;
	}
}

.review-header__cover {
	background: var(--color-primary);
	height: 80px;
	margin-bottom: var(--spacing-s);

	@include mq(large) {
		margin-bottom: var(--spacing-l);
	}

	.review-header--ecommerce & {
		background: var(--color-secondary);
	}

	.review-header--hosting & {
		background: var(--color-tertiary);
	}

	.review-header--chat & {
		background: var(--color-quaternary);
	}

	.review-header--email & {
		background: var(--color-quaternary);
	}

	.review-header--crm & {
		background: var(--color-quinary);
	}
}

.review-header.has-image .review-header__cover {
	min-height: 100px;
	background-repeat: no-repeat;
	background-size: cover;

	@include mq(medium) {
		min-height: 170px;
	}

	@include mq(large) {
		min-height: 270px;
	}
}

.review-header__inner {
	display: grid;
	grid-gap: var(--grid-gap-s);
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: 70px auto;
	padding: 0 var(--spacing);

	@include mq(large) {
		grid-gap: var(--grid-gap-sm);
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: 1fr;
	}

	&:after {
		@include mq(large) {
			content: ' ';
			display: block;
			border-bottom: 1px solid var(--color-nav-grey);
			grid-column: 4 / 13;
			grid-row: 2;
		}
	}
}

.review-header.has-nav .review-header__inner {
	grid-template-rows: 70px auto 50px;

	@include mq(large) {
		grid-template-rows: auto 50px;
	}

	&:after {
		@include mq(large) {
			display: none;
		}
	}
}

.review-header__site-logo {
	display: none;
}

.review-header__logo {
	grid-column: 1 / 3;
	grid-row: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.14);
	border-radius: var(--border-radius);
	margin-top: -25px;

	@include mq(large) {
		grid-column: 1 / 4;
		grid-row: 1 / 3;
		margin-top: -60px;
	}

	img {
		max-width: 80px;

		@include mq(large) {
			max-width: 200px;
			width: 100%;
        	padding: 0 10px;
		}
	}
}

.review-header__cta {
	grid-column: 3 / 6;
	grid-row: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	@include mq(large) {
		grid-column: 11 / 13;
		grid-row: 1;
	}
}

.review-header__cta .btn {
	font-size: var(--font-size-xsmall);
}

.review-header__score {
	grid-column: 1 / 2;
	grid-row: 2;

	@include mq(large) {
		grid-column: 4 / 5;
		grid-row: 1;
	}
}

.review-header__score-label {
	display: none;
}

.review-header__summary {
	grid-column: 2 / 6;
	grid-row: 2;

	@include mq(large) {
		grid-column: 5 / 11;
		grid-row: 1;
		display: flex;
		justify-content: space-between;
	}
}

.review-header__summary-item {
	flex: 1;
	display: flex;
	align-items: flex-start;
	padding: 0;

	p {
		margin: 1px 0 0 0;
	}

	ul.checklist {
		margin-left: 10px;
	}

	.checklist li {
		margin-bottom: 6px;
		font-size: var(--font-size-small);
		line-height: var(--line-height-headings);
		display: none;
	}

	.checklist li:first-child {
		display: block;
	}

	.checklist li:before {
		top: -2px;
	}

	@include mq(large) {
		flex-direction: column;
		align-items: flex-start;
		border-left: 1px solid var(--color-nav-grey);
		padding: 0 var(--spacing);

		p {
			margin: 0;
		}

		ul.checklist {
			margin-left: 0;
		}

		.checklist li {
			margin-bottom: 0;
			padding-left: 30px;
			display: block;
			line-height: var(--line-height-small);
		}

		.checklist li:before {
			top: 2px;
		}

		&:last-child {
			border-right: 1px solid var(--color-nav-grey);
		}
	}
}

.review-header__summary-item--pricing .checklist {
	display: none;

	@include mq(large) {
		display: block;
	}
}

.review-header__starting-from {
	font-size: var(--font-size-small);
	line-height: 1;
	padding-left: 10px;

	@include mq(large) {
		padding-left: 0;
	}
}

.review-header__pro-con-anchor {
	text-decoration: none;
	display: flex;
	align-items: flex-start;

	&:hover {
		text-decoration: none;
		color: var(--color-text-base);
	}

	@include mq(large) {
		flex-direction: column;
		align-items: flex-start;

	}
}

.review-header__summary-title {
	text-transform: uppercase;
	font-size: var(--font-size-xsmall);
	color: var(--color-text-alt);
	margin-right: 10px;
	line-height: var(--line-height-headings);

	@include mq(large) {
		margin-bottom: 5px!important;
	}
}

.review-header__nav {
	grid-column: 1 / 6;
	grid-row: 3;

	@include mq(large) {
		grid-column: 4 / 13;
		grid-row: 2;
	}
}

.review-nav {
	display: flex;
	grid-gap: var(--spacing);
}



// Scroll indicator
.scroll-indicator {
	position: fixed;
	top: 0;
	height: 5px;
	background: var(--color-primary);
	transition: .2s;
	opacity: 1;
	z-index: z-scale(scroll-indicator);
}



// Fixed header
.review-header--not-top {
	@include mq(large) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		margin-top: 0;
		padding: 10px 0;
		box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
		// transition: all 200ms linear;
		z-index: z-scale(nav-overlay);

		.review-header__breadcrumbs {
			display: none;
		}

		.review-header__inner {
			grid-template-rows: 1fr!important;
			gap: 0; // var(--grid-gap-s);
		}

		.review-header__inner:after {
			display: none;
		}

		.review-header__cover,
		.review-header__nav {
			display: none;
		}

		.review-header__site-logo {
			display: flex;
			align-items: center;

			@include mq(large) {
				grid-column: 1 / 2;
				grid-row: 1 / 2;
			}
		}

		.review-header__tt-logo {
			.icon-logo {
				width: 110px;
				height: 20px;
			}

			&:hover {
				color: var(--color-text-base);
			}
		}

		.review-header__logo {
			box-shadow: none;
			border-radius: 0;

			@include mq(large) {
				border-left: 1px solid var(--color-nav-grey);
				margin-top: 0;
				grid-column: 3 / 5;
			}
		}

		.review-header__logo img {
			max-height: 40px;
		}

		.review-header__score {
			display: flex;
			align-items: center;
			justify-content: center;

			@include mq(large) {
				margin-top: 0;
				grid-column: 2 / 3;
				padding: 0 1em;
        		display: flex;
        		flex-flow: row;
			}
		}

		.review-header__score-label {
			display: flex;
			text-transform: uppercase;
			margin-right: 10px;
			margin-top: 6px;
			line-height: 1;
			font-size: var(--font-size-small);
			color: var(--color-text-light);
			min-width: 70px;
		}

		.review-header__summary {
			@include mq(large) {
				grid-column: 5 / 11;
			}

		}

		.review-header__summary-item--pricing {
			display: none;
		}

		.checklist li:last-child,
		.checklist li {
			display: none;
		}

		.checklist li:first-child {
			display: block;
		}

		.scroll-indicator {
			opacity: 1;
		}
	}
}

body.admin-bar .review-header--not-top {
	@include mq(large) {
		top: 32px;
	}
}

.top-banner-sticky .review-header--not-top {

	@include mq(large) {
		top: auto!important;
	}

}

.top-banner-sticky .review-header-wrap {
	@include mq(large) {
		top: 0;
		margin-top: 0;
	}
}



body.admin-bar .top-banner-sticky .review-header--not-top {
    margin-top: -33px !important;
}