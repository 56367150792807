.img-optimisation__form {
	display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
   	@include mq(medium) {
		margin-top: var(--spacing-l);
	}

	input[type='submit'] {
		margin-left: var(--spacing);
	}
}

.optim-error   { color: #6B0505; }
.optim-success { color: var(--color-primary); }


.ett-magnet{
	cursor: pointer !important;
	padding: 0 !important;
	background: transparent !important;
	transition: filter 0.3s ease;
}

article {
    transition: background-color 0.3s ease;
}


/* When the image is visible, make it brighter and darken the parent background */

#top:has(img.in-viewport) {
    background-color: rgba(0, 0, 0, 0.8); /* Darken background */
	article,.u-bg-cream,.info-box-text{
		background-color: transparent!important;
		border: 0;
	}
	.toc__item.is-active .toc__link:before{
		color:#000;
		background-color: rgba(0, 0, 0, 0.8);
	}
	.toc__link{
		color:#000;
	}
	.content .checklist-cons ul li:before, 
	.content .checklist-pros ul li:before{
		background: transparent;
	}
}

#top article:has(img.in-viewport) img{
    opacity: 0.1;
}

#top article:has(img.in-viewport) a,
#top article:has(img.in-viewport) hr
{
    color:#000 !important;
}

#top article:has(img.in-viewport) div#ez-toc-container ul.ez-toc-list li.is-active>a:before,
#top article:has(img.in-viewport) hr,
#top article:has(img.in-viewport) .play-button
{
	background-color: rgba(0, 0, 0, 0.8); /* Darken background */
}

.ett-magnet.in-viewport {
	opacity: 1 !important;
}
