.back-to-top {
    position: fixed;
    bottom: 6rem;
    right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-light-grey);
	color: white;
    color: white;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    opacity: 0;
	z-index: z-scale(back-to-top);
    visibility: hidden;
    transition: all 0.2s ease;

    svg {
        height: 18px;
        width: 18px;
    }

    &:hover {
        color: white;
    }

    &.is-visible {
        opacity: 1;
        visibility: visible;
    }

	// adjust position for cookie bar
	.cookies-not-set & {
		bottom: 100px;
	}
}

.global-footer {
	position: relative;
	background-color: var(--color-grey);
	margin-top: auto;
	padding: 4rem var(--spacing) var(--spacing);
	min-height: 380px;

	p {
		color: white;
		margin: 0;
		font-size: var(--font-size-small);
	}

	&:before {
		content: ' ';
		display: block;
		height: 100px;
		width: 100px;
		background: url('../images/white-curve.svg') no-repeat;
		z-index: 1;
		position: absolute;
		top: -25px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.u-bg-cream + .global-footer:before,
.has-comments + .global-footer:before {
	background: url('../images/cream-curve.svg') no-repeat;
}

.global-footer__inner {
	@extend .container;
}

.global-footer__primary {
	text-align: center;
	display: flex;
	flex-wrap: wrap;

	@include mq(large) {
		
		justify-content: space-between;
		text-align: left;
	}
}

.global-footer__nav {
	order: 3;
	flex-basis: 100%;
	
	@include mq(large) {
		flex-basis: 50%;
		margin: var(--spacing-xs) 0 0 0;
	}
}

.footer-nav {
	a {
		display: block;
		color: white;
		white-space: wrap;
	}

	@include mq(large) {
		columns: 2;
		max-width: 80%;
	}
}

.global-footer__social {
	margin: var(--spacing-l) 0;
	order: 2;
	flex-basis: 100%;
				
	@include mq(large) {
		flex-basis: 50%;
		max-height: 50px;
		order: 2;
		text-align: right;
	}
}

.global-footer__newsletter {
	position: relative;
	margin: var(--spacing-l) 0;

	// #mc_embed_signup {
	// 	margin-bottom: var(--spacing-l);
	// }

	// label {
	// 	display: none;
	// }

	// input[type=email], input[type=text] {
	// 	width: 100%;
	// 	padding: 0;
	// 	background: transparent;
	// 	border: 0;
	// 	border-bottom: 1px solid white;
	// 	border-radius: 0;
	// 	font-size: var(--font-size-lead);
	// 	padding: 10px 0;
	// }

	// input[type=submit] {
	// 	position: absolute;
	// 	top: 10px;
	// 	right: 0;
	// 	background: url('../images/arrow-white.svg') no-repeat center right;
	// 	background-size: 40%;
	// 	color: transparent;
	// 	border-radius: 0;
	// 	border: 0;
	// 	margin: 0;
	// 	padding: 0;
	// }

	// .mc-field-group {
	// 	@include mq(medium) {
	// 		display: grid;
	// 		grid-template-columns: 1fr 1fr;
	// 		grid-gap: var(--grid-gap-sm);
	// 		grid-row-gap: 0;
	// 	}
	// }

	// #mc_embed_signup div.mce_inline_error {
	// 	border: 1px solid #6B0505;
	// 	border-radius: var(--border-radius);
	// 	background-color: var(--color-grey);
	// 	margin: 10px 0 0 0;
	// 	font-size: var(--font-size-xsmall);
	// 	font-weight: var(--font-weight-normal);
	// }
		order: 1;
		flex-basis: 100%;

	@include mq(large) {
		flex-basis: 50%;
		margin-bottom: 0;
		padding: 0 var(--spacing-xxl) 0 0;
	}
}

.global-footer__logo {
	color:#fff;
	min-height: 50px;
    width: 400px;
    max-width: 100%;

	@include mq(large) {
		
    	width: 330px;
    	text-align: right;
		// margin-top: var(--spacing-l);
	}
}

.footer-custom-logo{
	display: inline-block;
	max-width: 170px;
	@include mq(large){
		max-width: 340px;
	}
}

.global-footer__secondary {
	margin-top: var(--spacing-xl);
	text-align: center;
	order: 4;
	flex-basis: 100%;

	p {
		color: var(--color-light-grey);
		font-size: var(--font-size-small);
		
	}

	@include mq(large) {
		text-align: right;
		flex-basis: 50%;
		p{
			margin-right: 10px;
		}
	}
}

#tt-cookie-notice {
    position: fixed;
    min-width: 100%;
    height: auto;
    z-index: 3000;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    left: 0;
    text-align: center;
    font-weight: 400;
    bottom: 0;
    padding: 5px 0;
}

.tt-cookie{
	 
	.tt-button{
		font-family: sans-serif;
		padding: 2px 12px;
		border-color: #bbb;
		color: #333;
		text-shadow: 0 1px 0 #fff;
		-moz-box-sizing: border-box;
		border-radius: 3px;
		border-style: solid;
		border-width: 1px;
		cursor: pointer;
		display: inline-block;
		font-size: 12px;
		font-style: normal;
		text-decoration: none;
		white-space: nowrap;
		outline: none;
		height: 28px;
		margin: 5px;
		min-height: 24px;
		display: inline-block;
		margin-left: 20px;	
	}

	.tt-notice{ 
		width: 80%;
		max-width: 1200px;
		text-align: center;
		overflow: auto;
		margin: 0 auto;
	}

	#tt-text{
		display: inline-block;
	}

}

.tt-close-icon{

    float:right;
    margin-right: 30px;

	&:after {
		transform: rotate(-45deg);
	}

	&:before, &:after {
		position: absolute;
		content: ' ';
		height: 15px;
		width: 2px;
		top: 15px;
		background-color: #fff;
	}

	&:before {
    	transform: rotate(45deg);
	}

}	

#sib-footer-newsletter,  
#footer-newsletter{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}  

#footer-newsletter input[type="text"],
#footer-newsletter input[type="email"]{
  background: transparent;
  border-bottom: 1px solid #fff;
  border-right: 0;
  border-top: 0;
  border-left: 0;
  outline-width: 0;  
  color: #fff;
  margin: 0 0 10px;
  height: 45px;
} 

#footer-newsletter input[type="text"]:-webkit-autofill,
#footer-newsletter input[type="email"]:-webkit-autofill {
  background-color: transparent;
}
.ett-nl-email{
  flex:80%;
  display: flex;
}

#footer-newsletter .ett-nl-name{
  display:none;
}

#footer-newsletter .ett-nl-name, #footer-newsletter .ett-nl-legal-txt{
  display:none;
}
#footer-newsletter .ett-nl-email:not(:placeholder-shown) ~ .ett-nl-name{
  display: flex;
  order: 3;
  flex:100%;
}
#footer-newsletter .ett-nl-email:not(:placeholder-shown) ~ .ett-nl-legal-txt{
  display: block;
    order: 4;
    width: 100%;
    color: #fff;
    align-items: center;
    padding: 15px 0 25px;
}
#footer-newsletter .ett-nl-legal input[type="checkbox"]{
  display: inline;
}
#footer-newsletter .ett-nl-legal-txt span{
  display: inline;
    color: #fff;
    padding: 10px;
}
#footer-newsletter .ett-nl-legal-txt a{
  color: var(--color-primary);
}
#footer-newsletter button.btn-arrow{
  display: flex;
  order: 2;
  font-size: 32px;
  text-align: left;
  font-weight: 700;
  font-family: "Helvetica", sans-serif;
  color: #FFFFFF;
  background-color: transparent;
  border-width: 0px;
  flex: 10%;
  height: 45px;
  border-bottom: 1px solid #fff;
  justify-content: flex-end;
}
#footer-newsletter .btn-submit-form-ff{
    font-size: 16px;
    text-align: left;
    font-weight: 700;
    color: #FFFFFF;
    background-color: #3E4857;
    border-radius: 3px;
    border-width: 0px;
    display: flex;
    margin-left: 10px;
    padding: 10px 15px;
    cursor: pointer;
    display: block;
    margin: 10px auto 10px 0;
}
#footer-newsletter .btn-submit-form-ff:hover{
    background-color: var(--color-quaternary);
}