.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	padding: 1em 1.5em;
	margin: 1.5em 0;
	border: 1px solid var(--color-grey);
	background-color: transparent;
	color: var(--color-text-dark);
	font-family: var(--display-family);
	font-weight: var(--font-weight-bold);
	font-size: var(--font-size-xsmall);
	line-height: 1;
	text-transform: uppercase;
	text-decoration: none;
	cursor: pointer;
	transition: all 0.1s ease-in-out;

	&:hover,
	&:focus,
	&.is-active {
		border-color: var(--color-grey);
		background-color: var(--color-grey);
		outline: 0;
		color: var(--color-text-reversed);
		text-decoration: none;
	}

	&:disabled {
		opacity: .5;
		cursor: not-allowed;
	}
}

// .content {
// 	a.btn {
// 		color: var(--color-text-dark);
// 	}

// 	a.btn:hover {
// 		color: white;
// 		text-decoration: none;
// 	}
// }

.btn--small {
	padding: 0.5em 1em;
}

.btn--text-small {
	padding: 0.8em 2em;
	font-size: var(--font-size-xsmall);
	text-align: center;
}

.btn--full {
	width: 100%;
	text-align: center;
}

.btn--link {
	border: 1px solid transparent;
	text-decoration: underline;
	text-decoration-color: var(--color-text-light);

	&:hover, &:focus {
		border-color: transparent;
		text-decoration: underline;
		text-decoration-color: var(--color-text-base);
	}
}

.btn--primary {
	background-color: var(--color-primary);
	border: 1px solid var(--color-primary);
	color: var(--color-text-reversed);
}

.btn--secondary {
	background-color: var(--color-secondary);
	border: 1px solid var(--color-secondary);
	color: var(--color-text-reversed);
}

.btn--tertiary {
	background-color: var(--color-tertiary);
	border: 1px solid var(--color-tertiary);
	color: var(--color-text-reversed);
}

.btn--quaternary {
	background-color: var(--color-quaternary);
	border: 1px solid var(--color-quaternary);
	color: var(--color-text-reversed)!important;
}

.btn--quinary {
	background-color: var(--color-quinary);
	border: 1px solid var(--color-quinary);
	color: var(--color-text-reversed);
}

.btn--tab {
	margin: 0;
	text-transform: none;
	border-radius: 4px;
	padding: var(--spacing-s);
	color: var(--color-grey);
}

.btn svg {
	margin-left: 8px;
	height: 16px;
	width: 16px;
}

.btn--pill {
	margin: 0.25em;
	padding: 0.5em 0.8em!important;
	text-transform: none;
	border-radius: 20px;
	padding: var(--spacing-s);
	color: var(--color-grey);
	font-weight: var(--font-weight-normal);

	&.is-active {
		background: transparent;
		color: var(--color-primary);
		border-color: var(--color-primary);
	}

	&:hover {
		background: transparent;
		color: var(--color-primary);
		border-color: var(--color-primary);
	}
}

.show-more-btn-shortcode{
	margin: var(--spacing-l) auto;
    display: block;
    width: fit-content;
}

.show-more-large{
	width: auto;
	text-align: center;
}

a.btn.show-more-btn.show-more-btn-shortcode.show-more-large.is-active {
	color: transparent; /* Hide the original link content */
	visibility: hidden; /* Hide the original link content */
	display: initial;
}

a.btn.show-more-btn.show-more-btn-shortcode.show-more-large.is-active:before {
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
    font-family: var(--display-family);
    font-weight: var(--font-weight-bold);
    justify-content: center;
    padding: 1em 1.5em;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .1s ease-in-out;
    content: attr(show-less-text);
    visibility: visible;
    background-color: var(--color-grey);
    border-color: var(--color-grey);
    color: var(--color-text-reversed);
    outline: 0;
    text-decoration: none;
    display: block;
    width: auto;
	margin-bottom: var(--spacing-xxl);
}