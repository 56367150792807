.page-header--ebook {
	padding-top: var(--spacing-xxl);
	padding-bottom: var(--section-spacing-l);
	@include mq(medium) {
		text-align: left;
		padding-top: var(--section-spacing-l);
		ul{
		    list-style: disc;
		}
	}

	.form {
		text-align: left;

		.btn {
		    @include mq($until: medium) {
				width: 80%;
			    display: block;
			    margin: 1.5em auto;
		    }
		}
	}
	ul{
		list-style: disc;
	}
}