.rating-circle {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	overflow: hidden;

	span {
		font-size: var(--font-size-lead);
		font-weight: var(--font-weight-bold);
		z-index: 2;
	}

	&::after {
		content: "";
		position: absolute;
		top: 3px;
		bottom: 3px;
		right: 3px;
		left: 3px;
		border-radius: 100%;
		background: white;
		z-index: 1;
	}

	@include mq(large) {
		width: 80px;
		height: 80px;

		&::after {
			top: 4px;
			bottom: 4px;
			right: 4px;
			left: 4px;
		}
	}
}

.rating-circle--small {
	width: 50px;
	height: 50px;

	@include mq(large) {
		width: 70px;
		height: 70px;
	}
}

.rating-circle--xsmall {
	width: 50px;
	height: 50px;
	margin: 0 auto;

	span {
		font-size: var(--font-size-small);
	}

	&::after {
		top: 2px;
		bottom: 2px;
		right: 2px;
		left: 2px;
	}
}

.rating-circle--cream:after {
	background: var(--color-cream);
}

// Fixed header
.review-header--not-top .rating-circle {
	width: 40px;
	height: 40px;
	min-width: 40px;

	span {
		font-size: var(--font-size-xsmall);
		font-weight: var(--font-weight-bold);
	}

	&::after {
		top: 2px;
		bottom: 2px;
		right: 2px;
		left: 2px;
	}
}
