.comparison__table {
	position: sticky;
	border-collapse: separate;
	margin-top: var(--spacing);
	border: 1px solid var(--color-table-border);
	@include mq(large) { table-layout: fixed; }

	th, td {
		border-right: 1px solid var(--color-table-border);
		vertical-align: middle;
		padding: .75rem .75rem;
		@include mq(medium) {
			padding: 1.5rem 1.5rem;
		}

		.stars {
			margin: 0 auto;
    		display: table;
		}
	}

	td:first-child small{

		font-weight: var(--font-weight-normal);

	}

	thead {

		top: 0;
		position: sticky;
		background-color: #fff;

		th {
			position: relative;
			text-align: center;

			.thumbnail-wrapper {
				height: 90px;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    margin: 0 auto var(--spacing) auto;
				img {
				    width: auto;
				    max-width: 180px;
				    max-height: 90px;
				}
			}
			.rating-circle { margin: 0 auto; }

			.store-size {
				font-size: 0.6rem;
			    margin: 0 0 15px 0;
			    padding: 0;
			    box-sizing: border-box;
			    padding: 3px 7px;
			    background: var(--color-cream);
			    border-radius: 100px;
			    color: var(--color-text-base);
			    display: inline-block;
			    text-align: center;
    			white-space: nowrap;
    			position: absolute;
				top: 10px;
				left: 50%;
				transform: translateX(-50%);
			}

			.remove-it {
				position: absolute;
				right: 15px;
				top: 10px;
			}
		}

		th:first-child { width: 200px; }
	}

	tbody{

		td:not(:first-child) span{

			text-align: center;
			display: block;
			
		}

	}

	.comparison__table--true-false {
		margin: 0 auto;
    	display: table;
	}

	.comparison__table--prices {
		p { margin-bottom: var(--spacing); }
		p:last-child { margin-bottom: 0; }
	}

	.remove-add {
		text-decoration: none;
		color: var(--color-text-light);
	}

	.tippy-box {
		background: #fefefe;
		//box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.04);
		border-radius: 4px;
		.tippy-content {
			padding: 10px 20px;
			p {
				color: rgba(0, 0, 0, 0.6);
				line-height: 22px;
			}
		}
	}

	img {
		max-width: 100%;
		width: 100%;
    	height: auto;
	}

	p { margin-top: 0; }

	.actions {
		text-align: center;
		.btn { text-decoration: none; }
	}

	.storage-space td:not(:first-child) { text-align: center; }
}

.side-by-side-section {
	@include mq($until: large) {
		overflow-x: scroll;
	}
}

.comparison__modal {
	padding: var(--spacing-l);
	border-radius: var(--border-radius);
	width: 95vw;
	@include mq($until: large) {
		height: 90vh;
		overflow-y: scroll;
		padding-bottom: 90px !important;
	}
	@include mq(medium) {
		padding: var(--spacing-xl);
		width: 75vw;
	}
	@include mq(large) { width: 66.6666vw; }

	.error-not-enough, .error-too-much {
		display: none;
	}

	.grid {
		margin-top: var(--spacing-xl);
		grid-template-columns: 1fr 1fr;
		@include mq(medium) {
			grid-template-columns: 1fr 1fr 1fr ;
		}

		@include mq(large) {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		}
	}

	h3 {
		padding-right: 30px;
		@include mq(medium) { padding-right: 0; }
	}

	.modal-button__wrap {
		@include mq($until: medium-major) {
			background: #fff;
		    position: fixed;
		    bottom: 0;
		    padding: var(--spacing) 0;
		    left: 0;
		    width: 100%;
		    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 12%);
		    border-bottom-left-radius: var(--border-radius);
		    border-bottom-right-radius: var(--border-radius);
		}
		.btn {
			display: table;
			margin: 0 auto;
			@include mq(medium-major) { margin: var(--spacing-xl) auto 0 auto; }
		}
	}

	.comparison__modal--selector {
		.comparison__modal--logo {
			width: 100%;
		    position: relative;
		    border-radius: 6px;
		    border: 1px solid #e5e5e5;
		    box-sizing: border-box;
		    padding-top: 66.66666%;
		}

		.comparison__modal--status {
			position: absolute;
			left: 5px;
			top: 5px;
			width: 16px;
			height: 16px;
			background: #FFFFFF;
			border: 1px solid rgba(0, 0, 0, 0.2);
			box-sizing: border-box;
			border-radius: 2px;
		}

		&.selected {
			.comparison__modal--status {
				border-color: var(--color-primary);
				background: #fff url("data:image/svg+xml,%3Csvg width='11' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.277 3.897 6 10 1' stroke='%2357C0F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat center center;
			}
			.comparison__modal--logo {
				background: var(--color-cream);
				border-color: var(--color-cream);
			}
		}
	}
}

.page-template-side-by-side, .page-template-side-by-side-ecommerce, .page-template-side-by-side-hosting, .page-template-side-by-side-ctt {
	.lity-close {
		@include mq($until: medium) {
			color: var(--heading-color);
			text-shadow: none;
		}
	}
}
@include mq($until:xlarge){

	.block-category-links__inner, .comments__inner, .container, .downloads-panel__inner, .feature-panel__inner, .global-footer__inner, .global-header__inner, .hero-header__inner, .icon-panel__inner, .numbers-panel__inner, .page-header__inner, .review-panel__inner, .user-reviews-header__inner {
		word-break: break-word;
	}

	.page-header--comparison
	{
		.hero-body__text, .btn{
			display: none;
		}
	}
	#side-by-side{

		overflow-x: unset;
		
		tr{
			display: flex;
			flex-wrap:wrap;
			justify-content: flex-start;
		}

		.page-header--comparison .btn{
			display: none;
		}

		td, th{
			padding: 1rem;
		}

		.btn{
			padding: 0.5em 1em;
		}

		thead th .rating-circle {
			height: 60px;
			width: 60px;
		}
	}
}
@include mq($until:medium){

	#side-by-side{

		th:first-child{

			padding: 0;
			display: flex;
    		align-items: center;

			p{
				margin: 5px 5px 0;
				display: inline-block;
			}
			
			p:nth-child(2){
				
				max-width: 250px;
			}
			
			.thumbnail-wrapper {
				margin: 0 auto 5px;
			}
		}
					
		td:first-child,th:first-child{

			width:100%!important;

		}

		th:nth-child(2), td:nth-child(2),
		th:nth-child(3), td:nth-child(3) {
			width: 50%;
		}

		th:nth-child(4), td:nth-child(4) {
			clear: both;
			width: 100%;
		} 

		th:nth-child(4), th:nth-child(5), th:nth-child(6),
		td:nth-child(4), td:nth-child(5), td:nth-child(6)		
		{
			display: none;
		}

		thead th .thumbnail-wrapper img {
			max-height: 90px;
			max-width: 100%;
			width: auto;
		}

		/*
		Hidding rating circle on mobile

		thead th .rating-circle {
			height: unset;
    		width: unset;
    		background: unset !important;
		}*/
		
	}
}


@include mq($from:medium, $until:xlarge){
	
	#side-by-side{

		.comparison__table p {
			margin: 0 var(--spacing) var(--spacing-s);
			display: inline-block;
			vertical-align: middle;
		}
			
		th:first-child{
			padding: 0.2rem;
		}

		th:first-child, td:first-child{
			width:100%!important;
		}

		th:first-child p{
			display: inline-block;
			margin: 0 var(--spacing);
		}
		th:first-child p.mobile-portrait{
			display: none;
		}

		

		th:nth-child(2), td:nth-child(2),
		th:nth-child(3), td:nth-child(3),
		th:nth-child(4), td:nth-child(4),
		th:nth-child(5), td:nth-child(5),
		th:nth-child(6), td:nth-child(6){
			max-width: 20%;
			min-width: 20%;
			width: auto;
			flex-basis: min-content;
			word-break: break-word;
			padding: 0.5rem;
		}

		thead th .thumbnail-wrapper {
			height: 70px;
			flex-basis: min-content;
			margin: 0;
		}

		thead th .thumbnail-wrapper img {
			max-height: 60px;
			max-width: 100%;
			width: auto;
		}
		
		.stars--medium {
			font-size: 16px;
		}

		
	}
}

@include mq($from:medium,$until:medium-major){

	#side-by-side{
		
		.comparison__table{

			th {

				.rating-circle {
					background: none!important;
					height: unset;
					width: unset;
				}

				.rating-circle:after {
					display: none;
				}

				&:first-child{
					padding: 0.25rem;
				}

				p{
					margin: 0 var(--spacing);
				}
			}
			
		}	
	}	

}

@include mq($from:xlarge){

	.mobile-portrait{
		display: none;
	}

}