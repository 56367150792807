.sidebar__header {
	display: none;

	@include mq(large) {
		display: block;
		padding-bottom: var(--spacing-s);
		margin-bottom: var(--spacing);
		font-size: var(--font-size-small);
		border-bottom: 1px solid var(--color-nav-grey);
	}
}

.sidebar__comments {
	color: #a3a4a7;
	margin-top: 0;

	&:before {
		content: '';
		width: 20px;
		height: 20px;
		position: relative;
		margin-right: 8px;
		top: 4px;
		background: url("data:image/svg+xml,%3Csvg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.4' d='M11 0.998291C4.93448 0.998291 0 4.91576 0 9.73097C0.0160556 11.6122 0.743478 13.4199 2.04006 14.8007L1.22198 20.0022C1.19619 20.165 1.21857 20.3317 1.28643 20.4823C1.35429 20.6329 1.46475 20.761 1.6046 20.8513C1.74444 20.9417 1.90774 20.9903 2.07494 20.9916C2.24214 20.9928 2.40614 20.9465 2.54733 20.8582L7.21827 17.933C8.44679 18.2862 9.7203 18.464 11 18.4612C17.0655 18.4612 22 14.5448 22 9.73097C22 4.91576 17.0655 0.998291 11 0.998291ZM11 17.2567C9.74951 17.2602 8.506 17.0731 7.31353 16.7021L7.0414 16.6167L2.54878 19.4302L3.33867 14.4068L3.13218 14.1924C1.88275 12.894 1.22222 11.3513 1.22222 9.73097C1.22222 5.57989 5.60862 2.2028 11 2.2028C16.3914 2.2028 20.7778 5.57989 20.7778 9.73097C20.7778 13.8806 16.3914 17.2567 11 17.2567Z' fill='%231A1C22'/%3E%3C/svg%3E%0A") no-repeat center center;
		background-size: contain;
		display: inline-block;
	}
}

ul.toc,.toc {
	display: none;
	position: sticky;
	top: 120px;
	padding: var(--spacing);
	border-top: 1px solid var(--color-nav-grey);

	&.is-active {
		display: block;
	}

	@include mq(large) {
		display: block;
		padding: 0 0 var(--spacing);
		border-top: 0;
	}
}

ul.toc {
	display: none;
	position: sticky;
	top: 120px;
	padding: var(--spacing);
	border-top: 1px solid var(--color-nav-grey);

	&.is-active {
		display: block;
	}

	@include mq(large) {
		display: block;
		padding: 0;
		border-top: 0;
	}
}

.toc__item {
	margin: 0.3rem 0;
	line-height: 1;
}

.toc__link {
	position: relative;
	display: block;
	padding: 0.5em 0 0.5em 16px;
	color: var(--color-text-light);
	font-size: var(--font-size-small);
	transition: all 0.3s ease;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		display: block;
		height: 8px;
		width: 8px;
		background: white;
		border-radius: 8px;
		transform: translateY(-50%);
		opacity: 0;
		transition: all 0.3s ease;
	}
}

.toc__item.is-active .toc__link {
	color: var(--color-primary);
	padding-left: 16px;

	&:before {
		background: var(--color-primary);
		opacity: 1;
	}
}

// Mobile block styling
.sidebar__mobile {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: var(--spacing-s);

	@include mq(large) {
		display: none;
	}
}

.sidebar__btn {
	background: transparent;
	border: 0;
	padding: 0;
	margin: 0;
	color: var(--color-primary);

	svg {
		width: 14px;
		height: 14px;
		margin-left: 5px;
	}

	&.is-active svg {
		transform: rotate(180deg);
	}
}

.sidebar__mobile .btn {
	margin: 0;
}

// Fixed header
.review-sidebar-nav {
	@include mq($until: large) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background: white;
		box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
		transition: all 200ms linear;
		visibility: hidden;
		opacity: 0;
		z-index: z-scale(nav-overlay);

		&--not-top {
			visibility: visible;
			opacity: 1;
			transform: translateY(48px);
		}

		&--pinned {
			transform: translateY(48px);
		}
	}
}

// Hide when mobile menu is open
.nav-open .review-sidebar-nav {
	opacity: 0;
	visibility: hidden;
}
