.what-can-you-find-panel {
	text-align: center;
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);
	@include mq(medium) {
		text-align: left;
	}
	@include mq(large) {
		padding-top: var(--spacing-l);
		padding-bottom: var(--spacing-l);
	}
	.what-can-you-find-panel__header {
		text-align: center;
		margin-bottom: var(--spacing-xxl);
	}

	h3 { font-weight: 500; }
	h3 + p {
		margin-top: var(--spacing);
		@include mq(medium) { margin-top: var(--spacing-l); }
	}

	.grid {
		grid-gap: var(--grid-gap-s);
		@include mq(medium) { grid-gap: var(--grid-gap); }
	}

	a{
		color: var(--color-text-link);
	}
}